import { db, auth, firebase } from "@/helpers/firebase";

const COLLECTION = "Support";
const collectionRef = db.collection(COLLECTION);

class NewsDS {
  async send(data) {
    try {
      const ts = Date.now();
      const ticket = {
        subject: data.subject,
        message: data.message,
        pending: true,
      };
      await collectionRef
        .doc(auth.currentUser.uid)
        .collection("tickets")
        .doc(`${ts}`)
        .set(ticket);
    } catch {
      return "Error sending message";
    }
  }

  async getTickets(pending, limit = 5) {
    try {
      const tickets = await collectionRef
        .doc(auth.currentUser.uid)
        .collection("tickets")
        .where("pending", "==", pending)
        .orderBy(firebase.firestore.FieldPath.documentId(), "desc")
        .limit(limit)
        .get();
      return tickets;
    } catch {
      return [];
    }
  }

  async getPending() {
    return await this.getTickets(true);
  }

  async getResolved() {
    return await this.getTickets(false);
  }

  async deleteTicket(id) {
    try {
      await collectionRef
        .doc(auth.currentUser.uid)
        .collection("tickets")
        .doc(id)
        .delete();
      return true;
    } catch {
      return false;
    }
  }
}

export default new NewsDS();
