<template>
  <div class="contact-wrapper">
    <slot></slot>
    <div class="neo-card">
      <template v-if="!sent">
        <p></p>
        <v-form ref="contactForm" v-model="valid" lazy-validation>
          <div class="neo-text-field">
            <div>{{ $t("enquiryType") }}</div>
            <v-select
              :rules="[rules.required]"
              v-model="enquiry"
              outlined
              :items="enquiries"
              :label="$t('selectTicketType')"
              dense
              item-text="name"
              return-object
            ></v-select>
          </div>
          <div class="neo-text-field">
            <div>{{ $t("issue") }}</div>
            <v-text-field
              v-model="subject"
              flat
              solo
              outlined
              dense
              :label="$t('issue')"
              counter
              :rules="[rules.required, rules.maxIssue]"
            ></v-text-field>
          </div>
          <div class="neo-text-field">
            <div>{{ $t("description") }}</div>
            <v-textarea
              v-model="message"
              flat
              solo
              outlined
              dense
              :label="$t('description')"
              counter
              :rules="[rules.required, rules.maxDescription]"
            ></v-textarea>
          </div>
          <div class="text-center mt-4">
            <v-btn
              large
              :disabled="!valid || disabled"
              color="primary"
              id="send-message"
              @click="validate"
            >
              <v-icon class="mr-2">mdi-send</v-icon>
              {{ $t("send") }}
            </v-btn>
            <VueRecaptcha
              :sitekey="this.key"
              @verify="onVerify"
              @expired="onExpired"
              ref="recaptcha"
              size="invisible"
              badge="inline"
              type="contact"
              :loadRecaptchaScript="false"
              theme="light"
            ></VueRecaptcha>
          </div>
        </v-form>
        <p class="reCAPTCHA-disclaimer text-center">
          This site is protected by reCAPTCHA and the Google
          <a target="_blank" href="https://policies.google.com/privacy"
            >Privacy Policy</a
          >
          and
          <a target="_blank" href="https://policies.google.com/terms"
            >Terms of Service</a
          >
          apply.
        </p>
      </template>
      <CommonSuccessCheckout
        v-else
        class="mt-6 mb-4"
        text="Thank you for contacting us! We will send you an email once we go live."
      />
    </div>
  </div>
</template>

<script>
import CommonSuccessCheckout from "@/components/Organisms/CommonSuccessCheckout";
import TicketsDS from "@/services/TicketsDS";
import { VueRecaptcha } from "vue-recaptcha";

export default {
  name: "TicketForm",
  components: {
    CommonSuccessCheckout,
    VueRecaptcha,
  },
  data: () => ({
    test: true,
    recaptchaOk: false,
    enquiry: "general",
    key: process.env.VUE_APP_RECAPTCHA_SITE,
    valid: false,
    sent: false,
    subject: "",
    message: "",
    disabled: false,
    rules: {
      required: (value) => !!value || "Required.",
      maxIssue: (v) => (v && v.length <= 30) || "Max 150 characters",
      maxDescription: (v) => (v && v.length <= 250) || "Max 150 characters",
    },
  }),
  computed: {
    enquiries() {
      return [
        { name: this.$t("generalEnquiry"), key: "general" },
        { name: this.$t("accountAndBilling"), key: "accbill" }
      ];
    },
  },
  methods: {
    errorMethod(e) {
      console.log("errorMethod ", e);
    },
    expiredMethod(e) {
      console.log("expiredMethod ", e);
    },
    validate() {
      if (this.$refs.contactForm.validate()) this.sendMessage();
    },
    onVerify(response) {
      if (response !== "") {
        this.sendMail();
      } else {
        return false;
      }
    },
    onExpired() {
      this.resetRecaptcha();
    },
    resetRecaptcha() {
      this.$refs.recaptcha.reset();
      this.disabled = true;
    },
    sendMessage() {
      this.$refs.recaptcha.execute();
    },
    sendMail() {
      const payload = {
        name: `${this.$store.state.userProfile.lastName} ${this.$store.state.userProfile.firstName} `,
        email: this.$store.state.user.email,
        phoneNumber: this.$store.state.user.phoneNumber,
        enquiry: this.enquiry.key,
        subject: this.subject,
        message: this.message,
      };
      this.$emit("newTicket", payload);

      TicketsDS.send(payload);
      
      fetch(process.env.VUE_APP_EMAIL_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      })
        .then(() => {
          this.sent = true;
          this.resetForm();
        })
        .catch((error) => {
          this.error = true;
          console.log(error)
        });
      this.sent = true;
      this.resetForm();
    },
    resetForm() {
      this.contactFormData = {
        name: "",
        email: "",
        message: "",
        phoneNumber: "",
      };
    },
  },
};
</script>

<style lang="scss">
.grecaptcha-badge {
  visibility: hidden;
}
</style>

<style lang="scss" scoped>
.reCAPTCHA-disclaimer {
  font-size: var(--font-size-super-small);
}
.contact-wrapper {
  margin: auto;
  @media only screen and (min-width: 1024px) {
    max-width: 80%;
  }
}
</style>
