<template>
  <div class="neo-avatar" :class="{ 'neo-avatar--big': big }">
    <div
      class="neo-avatar__figure"
      :style="{
        width: `${size}px`,
        height: `${size}px`,
        'border-color': borderColor,
      }"
    >
      <img
        v-if="photoURL"
        class="neo-avatar__image"
        :src="`${photoURL}${noCache ? '?' + Date.now() : ''}`"
        @error="replaceByDefault"
        alt=""
      />
      <v-icon
        v-if="verified"
        title="user verified"
        :size="big ? '24' : '17'"
        class="neo-avatar__verified"
        color="success"
        >mdi-shield-check</v-icon
      >
    </div>
    <div class="neo-avatar__name" v-if="username">{{ username }}</div>
  </div>
</template>

<script>
export default {
  name: "NeoAvatar",
  props: {
    username: String,
    photoURL: String,
    size: {
      default: 50,
    },
    borderColor: String,
    big: Boolean,
    noCache: { type: Boolean, default: true },
    verified: { type: Boolean, default: false },
  },
  methods:{
    replaceByDefault(e) {
      e.target.onError = null;
      e.target.style.display = 'none';
    }
  }
};
</script>

<style lang="scss" scoped>
.neo-avatar {
  $this: &;
  display: flex;
  flex-direction: column;
  align-items: center;
  &__figure {
    margin-right: 4px;
    margin-bottom: 4px;
    border-radius: 50%;
    border: 4px solid var(--color-primary);
    //overflow: hidden;
    background-image: url("../../assets/neo-spinner.svg");
    background-size: 80% auto;
    background-position: center;
    display: flex;
    position: relative;

    #{$this}--big & {
      width: 100px !important;
      height: 100px !important;
      @media only screen and (min-width: 1024px) {
        width: 200px !important;
        height: 200px !important;
      }
    }
  }
  &__image {
    border-radius: 50%;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  &__name {
    font-size: var(--font-size-4);
    font-weight: 600;
  }
  &__verified {
    position: absolute;
    padding: 2px;
    top: -10px;
    right: -10px;
    background: white;
    border-radius: 50%;
    border: 1px solid var(--color-success);
    @media only screen and (max-width: 1023px) {
      #{$this}--big & {
        top: -5px;
        right: 0px;
        font-size: 20px !important;
      }
    }
    @media only screen and (min-width: 1024px) {
      #{$this}--big & {
        top: 10px;
        right: 10px;
      }
    }
  }
}
</style>
