<template>
  <NeoModal
    v-if="showAdviseModal"
    :max-width="440"
    title-header="Advise"
    @close="cancel"
  >
    <v-card-text>
      <p class="text-center pa-4">
        {{ message }}
      </p>
    </v-card-text>
    <template slot="actions">
      <div class="checkout-advise-modal-footer">
        <v-btn @click="cancel" outlined color="primary">
          {{ $t("cancel") }}
        </v-btn>
        <v-btn color="primary" @click="ok">{{ $t("ok") }}</v-btn>
      </div>
    </template>
  </NeoModal>
</template>

<script>
import NeoModal from "@/components/Molecules/NeoModal";

export default {
  name: "PromptModal",
  components: {
    NeoModal,
  },
  props: {
    showAdviseModal: { type: Boolean, default: false },
    message: { type: String, default: "" },
  },
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    ok() {
      this.$emit("ok");
    },
  },
};
</script>

<style lang="scss" scoped>
.checkout-advise-modal-footer {
  display: flex;
  @media only screen and (max-width: 599px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    > button + button {
      margin-top: 16px;
    }
  }
  @media only screen and (min-width: 600px) {
    flex-direction: row;
    padding: 16px;
    > button + button {
      margin-left: 16px;
    }
  }
}
</style>
