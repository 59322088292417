<template>
  <v-dialog
    ref="dialog"
    :value="value"
    :content-class="contentClass"
    :eager="eager"
    :fullscreen="fullscreen"
    :hide-overlay="hideOverlay"
    :max-width="maxWidth"
    :no-click-animation="noClickAnimation"
    :overlay-color="overlayColor"
    :overlay-opacity="overlayOpacity"
    :persistent="persistent"
    :scrollable="scrollable"
    :transition="transition"
    :width="width"
    @click:outside="clickOutsideCallback"
    @keydown.esc="handleClose"
    @input="handleInput"
  >
    <template v-slot:activator="{ on }">
      <slot name="activator" :on="on" />
    </template>

    <v-card
      class="neomodal"
      :min-height="minHeight"
      :class="{ colorText: onlyClose }"
    >
      <!--common header-->
      <div v-if="titleHeader" :class="{ 'neomodal-header': titleHeader }">
        <span class="neomodal-header__title">{{ titleHeader }}</span>
        <div class="d-flex align-center neomodal-header__last">
          <v-btn icon @click="handleClose">
            <v-icon color="base" size="30">mdi-close</v-icon>
          </v-btn>
        </div>
      </div>

      <!--only close-->
      <v-btn
        v-if="onlyClose"
        class="ml-3 neomodal-header__onlyclose"
        icon
        small
        @click="handleClose"
      >
        <v-icon size="16" color="white">icon-close</v-icon>
      </v-btn>
      <slot />

      <v-card-actions
        v-if="$slots.actions"
        class="justify-center pb-4 pt-6"
        style="box-shadow: rgba(10, 22, 70, 0.1) -5px -5px 5px"
      >
        <slot name="actions" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'NeoModal',
  props: {
    contentClass: {
      type: String,
      default: undefined,
    },
    eager: {
      type: Boolean,
      default: false,
    },
    fullscreen: {
      type: Boolean,
      default: false,
    },
    hideOverlay: {
      type: Boolean,
      default: false,
    },
    maxWidth: {
      type: [String, Number],
      default: 'none',
    },
    minHeight: {
      type: [String, Number],
      default: 'none',
    },
    noClickAnimation: {
      type: Boolean,
      default: true,
    },
    overlayColor: {
      type: String,
      default: 'black',
    },
    overlayOpacity: {
      type: Number,
      default: 0.6,
    },
    persistent: {
      type: Boolean,
      default: true,
    },
    scrollable: {
      type: Boolean,
      default: false,
    },
    transition: {
      type: String,
      default: 'dialog-transition',
    },
    value: {
      type: Boolean,
      default: true,
    },
    width: {
      type: [String, Number],
      default: 'auto',
    },
    titleHeader: {
      type: String,
      default: '',
      required: false,
    },
    back: {
      type: Boolean,
      default: false,
    },
    onlyClose: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      maxWidthRatio: 860,
    };
  },
  watch: {
    // enabled/disabled html scroll for use with v-slot:activator
    value: function(val) {
      if (val) {
        document.querySelector('html').style.overflowY = 'hidden';
      } else {
        document.querySelector('html').style.overflowY = '';
      }
    },
  },
  mounted() {
    // disabled html scroll when open
    if (this.value) {
      document.querySelector('html').style.overflowY = 'hidden';
    }
  },
  beforeDestroy() {
    // enabled html scroll when close
    document.querySelector('html').style.overflowY = '';
  },
  methods: {
    clickOutsideCallback() {
      this.focusOnContent();
    },
    focusOnContent() {
      this.$refs.dialog.$refs.content.focus();
      this.$emit('clickOutsideCallback');
    },
    handleClose(event) {
      this.$emit('close', event);
    },
    handleInput(newValue) {
      this.$emit('input', newValue);
    },
  },
};
</script>

<style lang="scss">
// add `content-class="not-vertical-center"` when use small neomodals
.v-dialog.not-vertical-center {
  margin-bottom: auto;
  margin-top: 15vh;
  @media only screen and (min-height: 900px) {
    margin-top: 188px;
  }
  .v-card .v-card__text {
    padding-left: 32px;
    padding-right: 32px;
  }
}
.v-dialog > .v-card > .v-card__text {
  padding-top: 24px;
  padding-bottom: 0;
}

.neomodal {
  > .v-card__text {
    padding-top: 16px !important;
  }
}
</style>

<style scoped lang="scss">
.neomodal-header {
  $this: &;
  flex-shrink: 0;
  position: relative;
  z-index: 1;
  width: 100%;

  display: grid;
  grid-template-columns: 28px 1fr auto;
  grid-auto-rows: 24px;
  align-items: center;
  justify-items: center;
  align-content: center;
  text-align: center;
  padding: 14px 12px 12px 12px;

  > .neomodal-header__last {
    margin-left: auto;
  }

  border-radius: 4px;
  box-shadow: var(--shadow1);
  background-color: #ffffff;

  &__title {
    font-size: var(--normal);
    grid-column-start: 2;
    font-weight: 600;
    grid-column-start: 2;
  }

  &__onlyclose {
    position: absolute;
    top: 8px;
    right: 8px;
    z-index: 5;
  }
}
</style>
