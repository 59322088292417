<template>
  <div class="summary-page-wrap">
    <div class="summary-page">
      <div class="summary-page__header">
        <div class="d-flex align-center">
          <NeoAvatar size="63" :photoURL="photoURL" />
          <div class="summary-page__title">
            <h1 class="ml-4 heading-size-3">{{ $t("customerService") }}</h1>
            <h2 v-if="username" class="ml-4 heading-size-3">
              {{ username }}
            </h2>
          </div>
        </div>
      </div>
      <CustomerService :pending="pending" :resolved="resolved" />
    </div>
  </div>
</template>

<script>
import CustomerService from "@/components/Templates/CustomerService";
import TicketsDS from "@/services/TicketsDS";
import NeoAvatar from "@/components/Molecules/NeoAvatar";

export default {
  name: "vCustomerService",
  components: {
    CustomerService,
    NeoAvatar,
  },
  data: () => ({
    pending: [],
    resolved: [],
  }),
  computed: {
    username() {
      return this.$store.state.userProfile.username || '';
    },
    photoURL() {
      return `${process.env.VUE_APP_IMAGES_URL}avatars/${this.$store.state.user.uid}.jpg`;
    },
  },

  async beforeMount() {
    const pendingSnapshot = await TicketsDS.getPending();
    if (pendingSnapshot.size > 0) {
      const pending = pendingSnapshot.docs;
      pending.forEach((doc) => {
        const element = doc.data();

        this.pending.push({
          id: doc.id,
          date: new Date(Number(doc.id)),
          subject: element.subject,
          message: element.message,
          pending: element.pending
        });
      });
    }
    const resolvedSnapshot = await TicketsDS.getResolved();
    if (resolvedSnapshot.size > 0) {
      const resolved = resolvedSnapshot.docs;
      resolved.forEach((doc) => {
        const element = doc.data();

        this.resolved.push({
          id: doc.id,
          date: new Date(Number(doc.id)),
          subject: element.subject,
          message: element.message,
          pending: element.pending
        });
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.summary-page-wrap {
  background-color: var(--color-white);
  height: 100%;
}

.summary-page {
  height: 100%;
  padding: 24px 0 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  &__header {
    text-align: left;
    width: var(--main-width-container);
    margin: auto;
  }

  &__title {
    h1 {
      line-height: 0.75;
    }
    h2 {
      line-height: 1;
    }
  }

  &__section-title {
    font-weight: 400;
    margin-bottom: 24px;
  }

  &__avatar {
    border-radius: 50%;
    width: 65px;
    height: 65px;
    border: 2px solid var(--color-primary);
    display: flex;
    overflow: hidden;
    img {
      object-position: center;
      object-fit: cover;
    }
  }
}
</style>
