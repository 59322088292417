<template>
  <div class="l-two-columns-center">
    <div>
      <slot name="col-1"></slot>
    </div>
    <div>
      <slot name="col-2"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TwoColumnsCenter',
};
</script>

<style lang="scss" scoped>
.l-two-columns-center {
  padding: 12px 8px;
  @media only screen and (min-width: 1024px) {
    padding: 24px;
    display: grid;
    grid-gap: 12px;
    grid-template-columns: 400px 1fr;
    max-width: 1240px;
    margin: auto;
  }
  > div:last-child {
    margin-top: 12px;
    padding: 12px;
    border-radius: 4px;
    background-color: var(--color-grey-light);
    @media only screen and (min-width: 1024px) {
      margin-top: 0;
    }
  }
}
</style>
