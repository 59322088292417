export default {
  methods: {
    getColor(diff) {
      if (diff < 0) return 'red';
      else if (diff > 0) return 'green';
      else return 'grey';
    },
    getIcon(diff) {
      if (diff < 0) return 'mdi-arrow-down';
      else if (diff > 0) return 'mdi-arrow-up';
      else return 'mdi-arrow-right';
    },
  },
};
