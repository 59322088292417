<template>
  <div class="transactions">
    <PromptModal
      :showAdviseModal="showAdviseModal"
      @cancel="cancelAction"
      @ok="deleteTicket"
      :message="promptMessage"
    />
    <h2>{{ $t("ticketList") }}</h2>
    <div>
      <h2 class="transactions__title heading-size-4">{{ $t("pending") }}</h2>
      <p v-if="!pending.length">{{ $t("noTicketsPending") }}</p>
      <template v-else>
        <TicketItem
          @deleteTicket="warning"
          v-for="(item, index) in pending"
          :value="item"
          :key="index"
        />
      </template>
    </div>

    <div v-if="resolved.length > 0">
      <h2 class="transactions__title heading-size-4">{{ $t("resolved") }}</h2>
      <p v-if="!resolved.length">{{ $t("noPreviousTickets") }}</p>
      <template v-else>
        <TicketItem
          @deleteTicket="warning"
          v-for="(item, index) in resolved"
          :value="item"
          :key="index"
        />
      </template>
    </div>
  </div>
</template>

<script>
import TicketItem from "@/components/Molecules/Tickets/TicketItem";
import PromptModal from "@/components/Organisms/PromptModal";
export default {
  name: "TicketList",
  components: {
    TicketItem,
    PromptModal,
  },
  data: () => ({
    showAdviseModal: false,
    ticketId: 0,
    promptMessage: "",
  }),
  props: {
    pending: {
      type: Array,
      default: function () {
        return [];
      },
    },
    resolved: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  methods: {
    cancelAction() {
      this.ticketToDelete = {};
      this.showAdviseModal = false;
    },
    deleteTicket() {
      this.$emit("deleteTicket", this.ticketToDelete);

      this.showAdviseModal = false;
    },
    warning(ticket) {
      this.promptMessage = `${this.$t("sureDeleteTicket")}:\n${ticket.message}`;
      
      this.showAdviseModal = true;
      this.ticketToDelete = ticket;
    },
  },
};
</script>

<style lang="scss" scoped>
.transactions {
  @media only screen and (min-width: 768px) {
    display: grid;
    grid-gap: 48px;
    grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
  }
  > div {
    @media only screen and (max-width: 767px) {
      margin-bottom: 48px;
    }
  }

  &__title {
    margin-bottom: 12px;
  }
}
</style>
