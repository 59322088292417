<template>
  <div class="transaction">
    <div class="transaction__first">
      <div class="transaction__date">
        <div>
          <div>{{ day }}</div>
          <div class="transaction__pipedate">/</div>
          <div>{{ month }}</div>
        </div>
        <div>{{ year }}</div>
      </div>
      <div class="transaction__name">
        <div class="d-flex">
          <div>{{ value.subject }}</div>
          <div class="delete-button" >
            <button type="button" @click="deleteTicket" title="Delete">
              <v-icon small color="base">mdi-trash-can-outline</v-icon>
            </button>
          </div>
        </div>
        <div class="transaction__title">{{ value.message }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import colorsAndIcons from "@/mixins/colorsAndIcons.js";
import helpers from "@/mixins/helpers.js";

export default {
  mixins: [colorsAndIcons, helpers],
  name: "TicketItem",
  props: {
    value: Object,
  },
  computed: {
    year() {
      return this.value.date.getFullYear();
    },
    month() {
      return this.leftFillNum(this.value.date.getMonth() + 1, 2);
    },
    day() {
      return this.leftFillNum(this.value.date.getDate(), 2);
    },
  },
  methods: {
    deleteTicket(){
      this.$emit('deleteTicket', this.value)
    }
  }
};
</script>

<style lang="scss" scoped>
.delete-button {
    margin-left: auto;
  }
.transaction {
  border-radius: 8px;
  margin-bottom: 0px;
  padding: 16px;
  font-size: 12px;
  line-height: 1.2;
  text-align: left;

  background-color: var(--color-grey-light);
  
  &:nth-child(2n) {
    background-color: var(--color-grey-dark);
  }

  &__first {
    grid-area: first;
    display: grid;
    grid-gap: 16px;
    align-items: center;
    grid-template-columns: 32px 1fr;
  }

  &__data {
    margin-top: 16px;
    display: grid;
    grid-gap: 12px;
    grid-template-columns: 1fr 1fr;
    @media only screen and (min-width: 600px) {
      grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    }
  }

  &__name {
    text-align: left;
    word-wrap: break-word;
    font-size: 1.5em;
    > :first-child {
      font-weight: 600;
      color: var(--color-primary);
    }
    > :last-child {
      font-size: 0.85em;
    }
  }

  &__date {
    width: 32px;
    font-size: 1.3em;
    font-weight: 600;
    > :first-child {
      display: flex;
      align-items: center;
      width: 100%;
      letter-spacing: -0.075em;
    }
  }
  &__pipedate {
    font-size: 0.85em;
  }

  &__cypher {
    font-size: 2em;
    font-weight: 400;
  }

  &__label {
    font-weight: 200;
  }

  &__title {
    @media only screen and (min-width: 600px) {
      width: 99%;
      word-wrap: break-word;
      overflow: hidden;
    }
  }
}
</style>
