<template>
<two-columns-center>
    <template slot="col-1">
      <TicketForm @newTicket="addTicket"/>
    </template>
    <template slot="col-2">
      <TicketList :pending="allPending" :resolved="allResolved" @deleteTicket="deleteTicket"/>
    </template>
  </two-columns-center>
 
</template>

<script>
import TicketList from "@/components/Organisms/Tickets/TicketList";
import TicketForm from "@/components/Organisms/Tickets/TicketForm";
import TwoColumnsCenter from "@/components/Templates/TwoColumnsCenter";
import TicketsDS from "@/services/TicketsDS"

export default {
  name: "CustomerService",
  components: { TwoColumnsCenter, TicketForm, TicketList },
  props: {
    pending: {type: Array, default: function(){ return []}},
    resolved: {type: Array, default: function(){ return []}},
  },
  data: () => ({
    rooms: [],
    messages: [],
    newPending: [],
    newResolved: [],
    deletePending: [],
    deleteResolved: [],
    open: false,
    ticketId: '0',
    subject: 'my subject',
  }),
  methods: {
    addTicket(ticket){
      
      ticket.date = new Date();
      ticket.pending = true;
      this.newPending = [ticket, ...this.newPending];
    },
    deleteTicket(ticket){
      TicketsDS.deleteTicket(ticket.id);
      if (ticket.pending){
        this.deletePending.push(ticket);
      } else {
        this.deleteResolved.push(ticket)
      }
    }
  },
  computed:{
    allPending(){
      let finalList = [...this.newPending, ...this.pending]
      this.deletePending.forEach((ticket)=>{
        finalList.splice(finalList.findIndex(v => v.id === ticket.id), 1);
      })
      return finalList;
    },
    allResolved(){
      let finalList = [...this.newResolved, ...this.resolved]
      this.deleteResolved.forEach((ticket)=>{
        finalList.splice(finalList.findIndex(v => v.id === ticket.id), 1);
      })

      return finalList;
    }
  },
};
</script>

<style scoped>
img {
  margin-top: 7vh;
  min-width: 280px;
  max-height: 500px;
}
</style>
