<template>
  <div :class="`common-${color}-checkout neo-card`">
    <div class="mb-8">
      <v-icon size="60" :color="color">{{icon}}</v-icon>
    </div>
    <div class="heading-size-4">
    <div v-html="text" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'CommonSuccessCheckout',
  props: {
    text: String,
    color: {type: String, default:'success'},
    icon: {type: String, default:'mdi-check-circle'},
    textColor: {type: String, default: 'var(--color-success)'}
  },
};
</script>

<style lang="scss" scoped>
.common-success-checkout {
  margin: auto;
  width: min(95%, 480px);
  border: 2px solid var(--color-success);
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 48px;
  color: var(--color-success);
  text-align: center;
}
.common-error-checkout {
  margin: auto;
  width: min(95%, 480px);
  border: 2px solid var(--color-error);
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 48px;
  color: var(--color-error);
  text-align: center;
}
</style>
